import React from "react";

import { Image } from "../../atoms";
import { Article } from "../../molecules";
import { graphics } from "../../../assets/images";
import { routesConfig } from "../../../config";

export default function OHomeHero({
	className,
	Hero,
}) {
	return (
		<section className={`o-section o-section--home-hero ${className}`}>
			<Article.MHero
				title={Hero}
				buttonLabel="Lees meer..."
				to={routesConfig.over_ons}
			/>
			<Image.ABackground src={graphics.main2} className="o-section--home-hero-graphic"/>
		</section>
	);
}
