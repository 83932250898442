import React from "react";
import { connect } from "react-redux";

import { actions as blogActions } from "../store/content/blog";
import { actions as pagesActions } from "../store/content/pages";
import { routesConfig } from "../config";
import { Section } from "../components/organisms";
import { Loader } from "../components/atoms";

const mapStateToProps = state => ({
	page: state.content.pages.home,
	overOns: state.content.pages.overOns,
	buy: state.content.pages.buy,
	contact: state.content.pages.contact,
	featured: state.content.blog.featured,
	isFetching: state.content.blog.isFetching,
});

export class HomePage extends React.Component {
	constructor(props) {
		super(props);

		this.bindHandles();
	}

	componentDidMount() {
		this.props.fetchPage();
		this.props.fetchOverOns();
		this.props.fetchContact();
		this.props.fetchBuyPage();
		this.props.fetchFeaturedBlogs();
	}

	bindHandles() {
		this.renderBlog = this.renderBlog.bind(this);
		this.handleBlogClick = this.handleBlogClick.bind(this);
	}

	renderBlog() {
		return ( this.props.featured?.length > 0 &&
				<Section.OHomeBlog
					{...this.props.featured[0]}
					onClick={() => this.handleBlogClick(this.props.featured[0]?.id)}
				/>
		);
	}

	handleBlogClick(id) {
		const blog = this.props.featured.find((blog) => blog.id === id);

		console.log(blog.Slug);

		this.props.history.push(routesConfig.blog.detail
			.replace(":slug", blog.Slug));
	}

	render() {
		if (this.props.isFetching) {
			return <Loader.ADefault />;
		}

		return (
			<main className="t-layout t-page--home">
				<Section.OHomeHero {...this.props.page}/>
				<Section.OOverOnsHoe {...this.props.overOns}/>
				<Section.OOntdek {...this.props.buy} />
				<Section.OHomeFilosofie {...this.props.page}/>
				{this.renderBlog()}
				<Section.OHomeBossen />
				<Section.OContact {...this.props.contact}/>
			</main>
		);
	}
}

export default connect(mapStateToProps, {
	fetchPage: pagesActions.fetchHome,
	fetchOverOns: pagesActions.fetchOverOns,
	fetchBuyPage: pagesActions.fetchBuy,
	fetchContact: pagesActions.fetchContact,
	fetchFeaturedBlogs: blogActions.fetchFeaturedBlogs,
})(HomePage);
