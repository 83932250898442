import React from "react";

import { Image } from "../../atoms";
import { graphics } from "../../../assets/images";
import { NavLink } from "react-router-dom";
import { routesConfig } from "../../../config";

export default function OHomeBossen({
	className,
}) {
	return (
		<section className={`o-section o-section-home-bossen ${className}`}>
			<div className="o-section-home-bossen-container">
				<h3 className="o-section-home-bossen-titel">
				aangeplante<br></br>bossen
				</h3>
				<a href="http://2015.planteenbos.be/loonbeek.php" rel="noreferrer" target="_blank">
					<div className="o-section-home-bos">
						<Image.ADefault src={graphics.bos1} className="o-section-home-bos-graphic" />
						<p className="o-section-home-bos-title">Loonbeek 2010*</p>
					</div>
				</a>
				<a href="http://2015.planteenbos.be/kaart_info.php" rel="noreferrer" target="_blank">
					<div className="o-section-home-bos">
						<Image.ADefault src={graphics.bos2} className="o-section-home-bos-graphic" />
						<p className="o-section-home-bos-title">Essene 2016*</p>
					</div>
				</a>
				<NavLink to={routesConfig.koop}>
					<div className="o-section-home-bos">
						<Image.ADefault src={graphics.bos3} className="o-section-home-bos-graphic"/>
						<p className="o-section-home-bos-title">Rekkem 2020</p>
					</div>
				</NavLink>
			</div>
			<p className="disclaimer">* Link naar vorige website</p>
		</section>
	);
}
