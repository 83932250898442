import React from "react";

import { Form } from "../../molecules";
import { graphics } from "../../../assets/images";
import { Image } from "../../atoms";
import ReactMarkdown from "react-markdown";

export default function OContact({
	Subtitel,
	Omschrijving,
}) {
	return (
		<section className="o-section o-section--contact">
			<div className="o-section--contact-content">
				<div className="o-section--contact-content-body">
					<h2 className="o-section--contact-content-body-title">Nieuwsbrief</h2>
					<p className="o-section--contact-content-body-hook">{Subtitel}</p>
					<p className="o-section--contact-content-body-text">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Omschrijving}
						/>
					</p>
				</div>
				<Form.MNewsletter className="o-section--contact-form"/>
			</div>
			<Image.ABackground src={graphics.page_1_onderkant} className="o-section--contact-graphic"/>
		</section>
	);
}
