import React from "react";


export default function MBuyNavigation({
	active1,
	active2,
	active3,
	KoopOne,
	KoopTwo,
	KoopThree,
}) {
	return (
		<section className="m-article--buy-navigation">
			<div className="o-section--item">
				<p className={`title ${active1 ? "active" : ""}`}>1/ KIES HOEVEELHEID</p>
				<p>{KoopOne}</p>
			</div>
			<div className="o-section--item">
				<p className={`title ${active2 ? "active" : ""}`}>2/ Bestel</p>
				<p>{KoopTwo}</p>
			</div>
			<div className="o-section--item">
				<p className={`title ${active3 ? "active" : ""}`}>3/ VOLTOOI</p>
				<p>{KoopThree}</p>
			</div>
		</section>
	);
}
