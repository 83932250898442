import React from "react";
import { sprites } from "../../../assets/images";
import { Image } from "../../atoms";


export default function MBuyLegend({
}) {
	return (
		<section className="m-article--buy-legend">
			<div className="line"></div>
			<h3>Legende</h3>
			<div className="legend-item-container">
				<div className="legend-item">
					<Image.ADefault src={sprites.zomerlinde} className="legent-item-img"/>
					<p>Zomerlinde</p>
				</div>
				<div className="legend-item">
					<Image.ADefault src={sprites.zwarte_els} alt="" className="legent-item-img"/>
					<p>Zwarte Els</p>
				</div>
				<div className="legend-item">
					<Image.ADefault src={sprites.schietwilg} alt="" className="legent-item-img"/>
					<p>Schietwilg</p>
				</div>
				<div className="legend-item">
					<Image.ADefault src={sprites.zomereik} alt="" className="legent-item-img"/>
					<p>Zomereik</p>
				</div>
				<div className="legend-item">
					<Image.ADefault src={sprites.bosrand} alt="" className="legent-item-img"/>
					<p>Bosrand</p>
				</div>
			</div>
		</section>
	);
}
