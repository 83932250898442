import React from "react";
import PropTypes from "prop-types";

import { Component } from "../../atoms";

export default function ANormalHeader({
	id,
	value,
	placeholder,
	active,
	className,
	iconClass,
	onClick,
	onKeyUp,
	setRef,
}) {
	return (
		<div
			id={id}
			role="button"
			tabIndex="0"
			aria-pressed={active}
			aria-expanded={active}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			className={`a-dropdown-header ${className} ${active ? "active" : ""}`}
		>
			<p className={`${!value && placeholder ? "placeholder" : ""}`}>{value || placeholder}</p>
			<Component.AIcon
				type={`arrow--${active ? "up" : "down"}`}
				className={`fill ${iconClass}`}
			/>
		</div>
	);
}

ANormalHeader.propTypes = {
	id: PropTypes.string.isRequired,
	active: PropTypes.bool,
	value: PropTypes.string,
	placeholder: PropTypes.string,

	className: PropTypes.string,
	iconClass: PropTypes.string,
	onClick: PropTypes.func,
	onKeyUp: PropTypes.func,
	setRef: PropTypes.any,
};

ANormalHeader.defaultProps = {
	active: false,
	value: "",
	placeholder: "",

	className: "",
	iconClass: "",
	onClick: () => undefined,
	onKeyUp: () => undefined,
	setRef: undefined,
};
