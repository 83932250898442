import axios from "axios";

import actionTypes from "./pages.actionTypes";
import { apiConfig } from "../../../config";

export const isFetching = () => dispatch => {
	dispatch({
		type: actionTypes.PAGES_IS_FETCHING,
	});
};
export const fetchingFailed = () => dispatch => {
	dispatch({
		type: actionTypes.FETCH_PAGES_FAILED,
	});
};
export const fetchingSucceeded = () => dispatch => {
	dispatch({
		type: actionTypes.FETCH_PAGES_SUCCEEDED,
	});
};

export const fetchHome = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.home);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_HOME,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchOverOns = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.over_ons);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_OVER_ONS,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchCta = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.cta);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_CTA,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchThanks = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.thanks);

		if (result.status !== 200 || !result.data.length) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_THANKS,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchBuy = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.buy);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_BUY,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchContact = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.contact);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_CONTACT,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchBlogPage = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.blog_page);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_BLOGPAGE,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchValidationPage = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.pages.validate);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_VALIDATION,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};
