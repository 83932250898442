import React from "react";

import { Image } from "../../atoms";

export default function MCheckoutAggregateItem({
	itemPrice,
	amount,
	src,
}) {

	function calculatePrice(amount, itemPrice) {
		return amount * itemPrice;
	}

	return (
		<div className="m-article--checkout-aggregateitem">
			<Image.ADefault src={src} />
			<p className="amount">€ {itemPrice}</p>
		</div>
	);
}
