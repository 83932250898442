import React from "react";

import { Navigation } from "../../molecules";
import { routesConfig } from "../../../config";
import { Image } from "../../atoms";
import { Link } from "react-router-dom";

export default function ODefault({
	className,
}) {
	return (
		<header className={`o-header o-header--default ${className}`}>
			<Link to={routesConfig.index} className="a-link--logo header-logo">
				<Image.ALogoRelative />
			</Link>
			<Navigation.MDefault />
		</header>
	);
}
