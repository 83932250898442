import React from "react";

export default function AListItemMenu({
	item,
	selected,
	className,
	onClick,
	onKeyUp,
	setRef,
	...props
}) {
	return (
		<li
			tabIndex="0"
			role="option"
			aria-selected={selected}
			onClick={onClick}
			onKeyUp={onKeyUp}
			ref={setRef}
			{...props}
			className={`a-dropdown-list-item ${selected ? "active" : ""} ${className}`}
		>
			<p>
				{item.value}
			</p>
		</li>
	);

}
