import React from "react";

import { logo } from "../../../assets/images";

export default function ALogoRelative({ className }) {
	return (
		<img
			src={logo}
			alt="logo"
			className={`a-image a-image--logo ${className}`}
		/>
	);
}
