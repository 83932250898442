import React from "react";
import PropTypes from "prop-types";

export default function AListWrapper({
	items,
	selected,
	className,
	onClick,
	onKeyUp,
	render,
	setRef,
}) {
	return (
		<ul className={`a-dropdown-list ${className}`}>
			{
				items.map((item, index) => render(
					{
						key: item.id,
						item,
						selected: item.id === selected,
						"aria-posinset": index,
						"aria-setsize": items.length,
						onClick: event => onClick({ event, index, id: item.id }),
						onKeyUp: event => onKeyUp({ event, index, id: item.id }),
						setRef: setRef,
					})
				)
			}
		</ul >
	);
}

AListWrapper.propTypes = {
	items: PropTypes.array,
	selected: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
	onKeyUp: PropTypes.func,
	render: PropTypes.func,
	setRef: PropTypes.any,
};

AListWrapper.defaultProps = {
	items: [],
	selected: undefined,
	className: "",
	onClick: () => undefined,
	onKeyUp: () => undefined,
	render: () => undefined,
	setRef: undefined,
};
