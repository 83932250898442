import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export default function ADefault({ to, className, children, onClick }) {
	return (<NavLink
		to={to}
		className={`a-link ${className}`}
		onClick={onClick}
		activeClassName="active"
		exact
	>
		{children}
	</NavLink>);
}


ADefault.propTypes = {
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.any,
	onClick: PropTypes.func,
};

ADefault.defaultProps = {
	className: undefined,
	children: undefined,
	onClick: () => undefined,
};
