import axios from "axios";

import actionTypes from "./blog.actionTypes";
import { apiConfig } from "../../../config";

export const isFetching = () => dispatch => {
	dispatch({
		type: actionTypes.BLOG_IS_FETCHING,
	});
};
export const fetchingFailed = () => dispatch => {
	dispatch({
		type: actionTypes.FETCH_BLOG_FAILED,
	});
};
export const fetchingSucceeded = () => dispatch => {
	dispatch({
		type: actionTypes.FETCH_BLOG_SUCCEEDED,
	});
};

export const fetchFeaturedBlogs = () => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(`${apiConfig.blogs.all}?Featured=true&_limit=1&_sort=created_at:desc`);

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_FEATURED_BLOGS,
			data: result.data,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchBlogs = () => async dispatch => {
	dispatch(isFetching());

	try {
		let query = "?_sort=Datum:DESC";

		const result = await axios.get(`${apiConfig.blogs.all}${query}`);

		if (result.status !== 200 || !result.data.length) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_BLOGS,
			data: {
				all: result.data,
			},
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchLastThree = () => async dispatch => {
	dispatch(isFetching());

	try {
		let query = "?_sort=created_at:DESC&_limit=3";

		const result = await axios.get(`${apiConfig.blogs.all}${query}`);

		if (result.status !== 200 || !result.data.length) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_LAST_THREE,
			data: {
				lastThree: result.data,
			},
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const fetchBlogBySlug = (slug) => async dispatch => {
	dispatch(isFetching());

	try {
		const result = await axios.get(apiConfig.blogs.detail.replace(":slug", slug));

		if (result.status !== 200) {
			dispatch(fetchingFailed());
			return;
		}

		dispatch({
			type: actionTypes.FETCH_BLOG_BY_SLUG,
			data: result.data[0],
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};
