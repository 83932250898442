import React from "react";
import { HashRouter } from "react-router-dom";
import { createBrowserHistory, createMemoryHistory } from "history";

import "./store/store";
import Routes from "./routes";

import "./assets/style/style.scss";

const history = typeof document !== "undefined" ? createBrowserHistory() : createMemoryHistory();

function App() {
	return (
		<HashRouter history={history}>
			<Routes />
		</HashRouter>
	);
}

export default App;
