import React from "react";
import PropTypes from "prop-types";

export default function MDefault({
	items,
	direction,
	className,
	children,
}) {
	return (
		<ul
			className={`m-list ${className}`}
			data-direction={direction}
		>
			{items.map(item => (<li key={item._id}>{React.cloneElement(children, item)}</li>))}
		</ul>
	);
}

MDefault.propTypes = {
	items: PropTypes.array,
	direction: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.any,
};

MDefault.defaultProps = {
	items: [],
	children: undefined,
};
