import React from "react";

import { Image } from "../../atoms";

export default function MHero({
	id,
	Titel,
	Datum,
	Auteur,
	Subtitel,
	Tag,
	onClick,
	className,
	Picture,
}) {

	return (
		<button onClick={() => onClick(id)} className="m-article--button">
			<article className={`m-article--blog-card ${className}`}>
				{Picture?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${Picture?.formats?.medium.url}`} className="m-article--blog-card-img"/>}
				<div className="m-article--blog-card-content">
					<p className="tag">{Tag}</p>
					<h2>{Titel}</h2>
					<p>{Subtitel}</p>
					<p className="about">door {Auteur} op {Datum}</p>
				</div>
			</article>
		</button>
	);
}
