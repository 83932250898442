import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

import { routesConfig } from "../config";
import { Loader } from "../components/atoms";
import { TemplatePage } from "../pages";

const HomePage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.home"),
	loading: () => <Loader.ADefault />,
	modules: ["home"],
});

const ContactPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.contact"),
	loading: () => <Loader.ADefault />,
	modules: ["contact"],
});

const OverOnsPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.over_ons"),
	loading: () => <Loader.ADefault />,
	modules: ["over_ons"],
});

const BlogPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.blog"),
	loading: () => <Loader.ADefault />,
	modules: ["blog"],
});

const BlogDetail = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.blog_detail"),
	loading: () => <Loader.ADefault />,
	modules: ["blog_detail"],
});

const BuyCardPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.buy_card"),
	loading: () => <Loader.ADefault />,
	modules: ["BuyCard"],
});

const BuyCheckoutListPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.buy_checkout_list"),
	loading: () => <Loader.ADefault />,
	modules: ["BuyCheckoutList"],
});

const BuyCheckoutPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.buy_checkout"),
	loading: () => <Loader.ADefault />,
	modules: ["BuyCheckout"],
});

const BuySuccesPage = Loadable({
	loader: () => import(/* webpackChunkName: "home" */ "../pages/pages.buy_succes"),
	loading: () => <Loader.ADefault />,
	modules: ["BuySuccesPage"],
});

const Routing = () => (
	<TemplatePage>
		<div className='container'>
			<Switch>
				<Route path={routesConfig.blog.detail} component={BlogDetail} />
				<Route path={routesConfig.blog.default} component={BlogPage} />
				<Route path={routesConfig.contact} component={ContactPage} />
				<Route path={routesConfig.over_ons} component={OverOnsPage} />
				<Route path={routesConfig.koop_succes} component={BuySuccesPage} />
				<Route path={routesConfig.koop_checkout_list} component={BuyCheckoutListPage} />
				<Route path={routesConfig.koop_checkout} component={BuyCheckoutPage} />
				<Route path={routesConfig.koop_kaart} component={BuyCardPage} />
				<Route component={HomePage} />
			</Switch>
		</div>
	</TemplatePage>
);

export default Routing;
