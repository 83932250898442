import React from "react";

import { routesConfig } from "../../../config";
import { Article } from "../../molecules";

export default function OHomeFilosofie({
	className,
	OverOns,
}) {
	return (
		<section className={`o-section o-section--home-filosofie ${className}`}>
			<div className="o-section--home-filosofie-content">
				<Article.MSection
					title="OVER ONS"
					description={OverOns}
					buttonLabel="Lees meer..."
					to={routesConfig.over_ons}
				/>
			</div>
		</section>
	);
}
