import actionTypes from "./pages.actionTypes";

const initialState = {
	home: {},
	overOns: {},
	cta: {},
	thanks: {},
	contact: {},
	blogpage: {},
	buy: {},
	valid: {},

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.PAGES_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_PAGES_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_PAGES_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};
		case actionTypes.FETCH_HOME:
			return {
				...state,
				home: action.data,
			};
		case actionTypes.FETCH_OVER_ONS:
			return {
				...state,
				overOns: action.data,
			};
		case actionTypes.FETCH_CTA:
			return {
				...state,
				cta: action.data,
			};
		case actionTypes.FETCH_THANKS:
			return {
				...state,
				thanks: action.data,
			};
		case actionTypes.FETCH_CONTACT:
			return {
				...state,
				contact: action.data,
			};
		case actionTypes.FETCH_BLOGPAGE:
			return {
				...state,
				blogpage: action.data,
			};
		case actionTypes.FETCH_BUY:
			return {
				...state,
				buy: action.data,
			};
		case actionTypes.FETCH_VALIDATION:
			return {
				...state,
				valid: action.data,
			};
		default:
			return state;
	}
}
