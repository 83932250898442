export default {
	index: "/",
	koop_succes: "/succes",
	over_ons: "/over_ons",
	koop_kaart: "/koop/smokkelbos",
	koop_checkout: "/koop/winkelmandje",
	koop_checkout_list: "/koop/overzicht",
	blog: {
		default: "/blog",
		detail: "/blog/:slug",
	},
	contact: "/contact",
};
