import React from "react";

import { Image } from "../index";
import { graphics } from "../../../assets/images";

export default function ADefault({ quote, className }) {
	return (
		<div className={`a-quote a-quote-default ${className}`}>
			<Image.ADefault src={graphics.quote} className="quote"/>
			<p className="quote-content">{quote}</p>
			{/*<Image.ADefault src={graphics.quote} className="quote right"/>*/}
		</div>
	);
}
