import React from "react";

import { Link } from "../../atoms";

export default function MSection({
	title,
	description,
	buttonLabel,
	to,
	className,
}) {
	return (
		<article className={`m-article m-article--centered m-article--section ${className}`}>
			<h2 className="m-article--section-title">{title}</h2>
			<p className="m-article--section-description">{description}</p>
			<Link.ACta to={to}>
				<p>{buttonLabel}</p>
			</Link.ACta>
		</article>
	);
}
