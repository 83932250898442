import React from "react";

import Container from "./_atoms.dropdown.container";
import NormalHeader from "./_atoms.dropdown.normal_header";
import ListItemMenu from "./_atoms.dropdown.list_item_menu";

export default function ADefault({
	value,
	className,
	onChange,
}) {
	const items = [{ id: "per_gebied", value: "Per gebied" }, { id: "globaal", value: "Globaal" }];

	return (<Container
		items={items}
		value={value || items[0].id}
		className={`a-dropdown-header--language-picker ${className}`}
		listClassName="language-picker--list"
		header={(props) => <NormalHeader className="language-picker--header" {...props} />}
		render={(props) => <ListItemMenu {...props} />}
		onChange={onChange}
	/>);
}
