import React from "react";

import { Image, Link } from "../../atoms";
import { routesConfig } from "../../../config";
import ReactMarkdown from "react-markdown";

export default function OOntdek({
	Omschrijving,
	ImgOne,
	ImgTwo,
}) {
	return (
		<section className="o-section o-section-ontdek">
			{ImgOne?.url && <Image.ADefault src={`https://cms.planteenbos.be${ImgOne?.url}`} className="o-section-ontdek-img-main"/>}
			<div className="o-section-ontdek-body">
				<div className="container-body">
					<h3>Ontdek het <br/>Smokkelbos in <br/>Rekkem (Menen)</h3>
					{ImgTwo?.url && <Image.ADefault src={`https://cms.planteenbos.be${ImgTwo?.url}`} className="o-section-ontdek-img-sub"/>}
					<p className="body"><ReactMarkdown
						parserOptions={{ commonmark: true }}
						source={Omschrijving}
					/></p>
				</div>
				<Link.ARound to={routesConfig.koop_kaart} className="o-section--cta-button">
					<p>Bekijk de kaart</p>
				</Link.ARound>
			</div>
		</section>
	);
}
