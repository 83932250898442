import React, { useRef, useEffect } from "react";
import { sprites } from "../../../assets/images";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { isBrowser, isMobile } from "react-device-detect";

const Canvas = ({ props, onBuy, trees, inCart }) => {
	const canvasRef = useRef(null);
	const lineRef = useRef(null);
	const messageRef = useRef(null);
	const container = useRef(null);
	const forSaleRef = useRef(null);

	const globalMultiplier = 0.8;

	const multiplier = (2 * globalMultiplier);
	const cardMultiplier = (1.80 * globalMultiplier);

	const offsetLeft = (160 * multiplier);
	const offsetTop = (310 * multiplier);

	// const width = (1264 * multiplier);
	const height = (1380 * multiplier);

	const gridWidth = (1200 * multiplier);

	const cardWidth = (1264 * cardMultiplier);
	const cardHeight = (1380 * cardMultiplier);

	const spriteSize = (20 * multiplier);
	const spriteSizeX = (13 * multiplier);
	const spriteSizeY = (20 * multiplier);

	const messageX = (170 * multiplier);
	const messageY = (95 * multiplier);
	const messageLeft = (75 * multiplier);
	const messageTop = (95 * multiplier);
	const textLeft = (65 * multiplier);
	const textTop = (55 * multiplier);
	const soldTop = (82 * multiplier);
	const forSaleTop = (82 * multiplier);

	const bottom = (160 * multiplier);
	const right = (255 * multiplier);

	const permittedTime = 1000 * 60 * 30;

	const timeAgo = Date.now() - permittedTime;

	const longtime = Date.now() - (permittedTime * 1000);

	useEffect(() => {
		drawCard();
		drawGrid();
		drawTrees();
		drawBigTrees();
		drawReserved();
		drawCarts();
	}, []);

	function hoverText(event) {
		const messages = messageRef.current;
		const messagesContext = messages.getContext("2d");

		const forSaleCanvas = forSaleRef.current;
		const forSaleContext = forSaleCanvas.getContext("2d");

		const hover = new Image();
		const squareWhite = new Image();

		hover.src = sprites.hover;
		squareWhite.src = sprites.squareWhite;

		const canvas = container.current.getBoundingClientRect();

		const addOffsetLeft = ((40 / 2) * multiplier);

		const coordinatesTop = ((20 / 2) * multiplier);

		const addOffsetTop = ((20 / 2) * multiplier); // (to position image not coordinates)

		let x = Math.ceil((((event.clientX - canvas.left) / (canvas.right - canvas.left) * canvas.width) - (offsetLeft + addOffsetLeft)) / spriteSize);

		let y = Math.ceil(((((event.clientY - canvas.top) / (canvas.bottom - canvas.top) * canvas.height) - offsetTop) - coordinatesTop) / spriteSize);

		let tree = getTypeSold(x, y);

		let reserved = getTypeReservedSingle(x, y);

		let forSale = getTypeForSale(x, y);

		if (tree) {
			hover.onload = function() {
				messagesContext.clearRect(0, 0, 2400, 2400);
				forSaleContext.clearRect(0, 0, 2400, 2400);

				messagesContext.font = "11px Sofia";

				let i;

				const maxWidth = 220;

				function getLines(text) {
					let words = text.split(" "),
						lines = [],
						line = "";

					if (messagesContext.measureText(text).width < maxWidth) {
						return [text];
					}
					while (words.length > 0) {
						let split = false;

						while (messagesContext.measureText(words[0]).width >= maxWidth) {
							let tmp = words[0];

							words[0] = tmp.slice(0, -1);
							if (!split) {
								split = true;
								words.splice(1, 0, tmp.slice(-1));
							} else {
								words[1] = tmp.slice(-1) + words[1];
							}
						}
						if (messagesContext.measureText(line + words[0]).width < maxWidth) {
							line += words.shift() + " ";
						} else {
							lines.push(line);
							line = "";
						}
						if (words.length === 0) {
							lines.push(line);
						}
					}
					return lines;
				}

				const lines = getLines((tree.type !== "bigtree" ? tree.message : "Voor Dave"));

				messagesContext.drawImage(hover, ((tree.positionX * spriteSize) + offsetLeft - messageLeft), ((tree.positionY * spriteSize) + offsetTop - messageTop), messageX, messageY);

				messagesContext.font = "11px Sofia";

				for (i = 0; i < lines.length; i++) {
					messagesContext.fillText(lines[i], ((tree.positionX * spriteSize) + offsetLeft - textLeft), (((tree.positionY * spriteSize) + offsetTop - textTop + i * 15)));
				}

				if (tree.owner) {
					const ownerMessage = `4 m² ${(tree.type === "zwarteEls" ? "zwarte els" : tree.type)} gekocht door ${tree.owner} uit ${tree.location}`;

					const ownerLines = getLines(ownerMessage);

					messagesContext.font = "13px Sofia";

					for (i = 0; i < ownerLines.length; i++) {
						messagesContext.fillText(ownerLines[i], ((tree.positionX * spriteSize) + offsetLeft - textLeft), (((tree.positionY * spriteSize) + offsetTop - soldTop + i * 15)));
					}

				}

				if (!tree.owner) {
					// const anoniemMessage = `4 m² ${tree.type === "zwarteEls" ? "zwarte els" : (tree.type === "bigtree" ? "bos" : tree.type)} gekocht uit sympathie`;
					const anoniemMessage = (tree.type !== "bigtree"
						? `4 m² ${tree.type === "zwarteEls" ? "zwarte els" : (tree.type === "bigtree" ? "bos" : tree.type)} gekocht uit sympathie`
						: "4 m² bos gekocht uit sympathie"
					);

					const anoniemLines = getLines(anoniemMessage);

					messagesContext.font = "13px Sofia";

					for (i = 0; i < anoniemLines.length; i++) {
						messagesContext.fillText(anoniemLines[i], ((tree.positionX * spriteSize) + offsetLeft - textLeft), (((tree.positionY * spriteSize) + offsetTop - soldTop + i * 15)));
					}
				}
			};
		}

		if(reserved) {
			hover.onload = function() {
				messagesContext.clearRect(0, 0, 2400, 2400);
				forSaleContext.clearRect(0, 0, 2400, 2400);

				messagesContext.drawImage(hover, ((reserved.positionX * spriteSize) + offsetLeft - messageLeft), ((reserved.positionY * spriteSize) + offsetTop - messageTop), messageX, messageY);

				messagesContext.font = "13px Sofia";

				messagesContext.fillText("Gereserveerd", ((reserved.positionX * spriteSize) + offsetLeft - textLeft), (((reserved.positionY * spriteSize) + offsetTop - forSaleTop)));
			};
		}

		if(forSale) {
			squareWhite.onload = function() {
				forSaleContext.clearRect(0, 0, 2400, 2400);

				forSaleContext.drawImage(squareWhite, ((forSale.positionX * spriteSize) + offsetLeft), ((forSale.positionY * spriteSize) + (offsetTop - addOffsetTop)), (20 * multiplier), (20 * multiplier));
			};

			hover.onload = function() {
				messagesContext.clearRect(0, 0, 2400, 2400);

				const message = `4 m² ${(forSale.type === "zwarteEls" ? "zwarte els" : forSale.type)} te koop voor 35€`;

				messagesContext.drawImage(hover, ((forSale.positionX * spriteSize) + offsetLeft - messageLeft), ((forSale.positionY * spriteSize) + offsetTop - messageTop), messageX, messageY);

				messagesContext.font = "15px Sofia";

				messagesContext.fillText(message, ((forSale.positionX * spriteSize) + offsetLeft - textLeft), (((forSale.positionY * spriteSize) + offsetTop - forSaleTop)));
			};
		}
	}

	function drawCard() {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");

		const background = new Image();

		background.src = sprites.kaart;

		background.onload = function() {
			context.drawImage(background, ((70 / 2) * multiplier), ((170 / 2) * multiplier), cardWidth, cardHeight);
		};
	}

	function drawTrees() {
		const lines = lineRef.current;
		const lineContext = lines.getContext("2d");

		const schietwilg = new Image();
		const zomereik = new Image();
		const zomerlinde = new Image();
		const zwarteEls = new Image();
		const bosrand = new Image();

		schietwilg.src = sprites.schietwilg;
		zomereik.src = sprites.zomereik;
		zomerlinde.src = sprites.zomerlinde;
		zwarteEls.src = sprites.zwarte_els;
		bosrand.src = sprites.bosrand;

		const addOffsetTop = ((20 / 2) * multiplier);

		schietwilg.onload = function() {
			trees.forEach(function(tree) {
				if(tree.type === "schietwilg" && tree.sold === true) {
					lineContext.drawImage(schietwilg, ((tree.positionX * spriteSize + ((5.25 / 2) * multiplier)) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		zomerlinde.onload = function() {
			trees.forEach(function(tree) {
				if(tree.type === "zomerlinde" && tree.sold === true) {
					lineContext.drawImage(zomerlinde, ((tree.positionX * spriteSize + ((5.25 / 2) * multiplier)) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		zwarteEls.onload = function() {
			trees.forEach(function(tree) {
				if(tree.type === "zwarteEls" && tree.sold === true) {
					lineContext.drawImage(zwarteEls, ((tree.positionX * spriteSize + ((5.25 / 2) * multiplier)) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		zomereik.onload = function() {
			trees.forEach(function(tree) {
				if(tree.type === "zomereik" && tree.sold === true && tree.message !== "DNS Belgium") {
					lineContext.drawImage(zomereik, ((tree.positionX * spriteSize + ((5.25 / 2) * multiplier)) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		bosrand.onload = function() {
			trees.forEach(function(tree) {
				if(tree.type === "bosrand" && tree.sold === true) {
					lineContext.drawImage(bosrand, ((tree.positionX * spriteSize + 5.25) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};
	}

	function drawBigTrees() {
		const lines = lineRef.current;
		const lineContext = lines.getContext("2d");

		const bigZomereik = new Image();
		const bigZomerlinde = new Image();
		const dnsBelgium = new Image();

		bigZomereik.src = sprites.big_zomereik;
		bigZomerlinde.src = sprites.big_zomerlinde;
		dnsBelgium.src = sprites.dnsBelgium;

		bigZomereik.onload = function() {
			lineContext.drawImage(bigZomereik, 1255, 1445, (spriteSizeY * 4.7), (spriteSizeY * 4.7));
		};

		bigZomerlinde.onload = function() {
			lineContext.drawImage(bigZomerlinde, 1030, 1220, (spriteSizeY * 4.7), (spriteSizeY * 4.7));
			lineContext.drawImage(bigZomerlinde, 997, 1060, (spriteSizeY * 4.7), (spriteSizeY * 4.7));
			lineContext.drawImage(bigZomerlinde, 1157, 1060, (spriteSizeY * 4.7), (spriteSizeY * 4.7));
			lineContext.drawImage(bigZomerlinde, 1190, 902, (spriteSizeY * 4.7), (spriteSizeY * 4.7));
		};

		dnsBelgium.onload = function() {
			lineContext.drawImage(dnsBelgium, 550, 1638, (spriteSizeY * 4.7), (spriteSizeY * 4.7));
		};
	}

	function drawCarts() {
		const lines = lineRef.current;

		const lineContext = lines.getContext("2d");

		const cart = new Image();

		cart.src = sprites.cart;

		const fullCart = new Image();

		fullCart.src = sprites.square;

		const addOffsetTop = ((20 / 2) * multiplier);

		const forSale = trees.filter(item => item.type !== ""
			&& item.sold !== true
			&& ((item.reserved ? new Date(item.reserved) : longtime) < timeAgo)
			&& item.type !== "bigtree"
		);

		cart.onload = function() {
			forSale.forEach(function(tree) {
				lineContext.drawImage(cart, ((tree.positionX * spriteSize) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), (20 * multiplier), (20 * multiplier));
			});
		};

		if (inCart.length) {
			fullCart.onload = function() {
				inCart.forEach(function(tree) {
					lineContext.drawImage(fullCart, ((tree.positionX * spriteSize) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), (20 * multiplier), (20 * multiplier));
				});
			};
		}
	}

	function drawReserved() {
		const lines = lineRef.current;

		const lineContext = lines.getContext("2d");

		const lo_schietwilg = new Image();
		const lo_zomereik = new Image();
		const lo_zomerlinde = new Image();
		const lo_zwarteEls = new Image();
		const lo_bosrand = new Image();

		lo_schietwilg.src = sprites.lo_schietwilg;
		lo_zomereik.src = sprites.lo_zomereik;
		lo_zomerlinde.src = sprites.lo_zomerlinde;
		lo_zwarteEls.src = sprites.lo_zwarte_els;
		lo_bosrand.src = sprites.lo_bosrand;

		let reservedTrees = getTypeReserved();

		const addOffsetTop = ((20 / 2) * multiplier); // CHANGE

		lo_schietwilg.onload = function() {
			reservedTrees.forEach(function(tree) {
				if(tree.type === "schietwilg") {
					lineContext.drawImage(lo_schietwilg, ((tree.positionX  * spriteSize) + offsetLeft + ((5.25 / 2) * multiplier)), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		lo_zomerlinde.onload = function() {
			reservedTrees.forEach(function(tree) {
				if(tree.type === "zomerlinde") {
					lineContext.drawImage(lo_zomerlinde, ((tree.positionX  * spriteSize) + offsetLeft + ((5.25 / 2) * multiplier)), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		lo_zwarteEls.onload = function() {
			reservedTrees.forEach(function(tree) {
				if(tree.type === "zwarteEls") {
					lineContext.drawImage(lo_zwarteEls, ((tree.positionX  * spriteSize) + offsetLeft + ((5.25 / 2) * multiplier)), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		lo_zomereik.onload = function() {
			reservedTrees.forEach(function(tree) {
				if(tree.type === "zomereik") {
					lineContext.drawImage(lo_zomereik, ((tree.positionX  * spriteSize) + offsetLeft + ((5.25 / 2) * multiplier)), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};

		lo_bosrand.onload = function() {
			reservedTrees.forEach(function(tree) {
				if(tree.type === "bosrand") {
					lineContext.drawImage(lo_bosrand, ((tree.positionX  * spriteSize) + offsetLeft + ((5.25 / 2) * multiplier)), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), spriteSizeX, spriteSizeY);
				}
			});
		};
	}

	function drawGrid() {
		const lines = lineRef.current;

		const lineContext = lines.getContext("2d");

		const addOffsetLeft = (20 * multiplier);
		const addOffsetTop = (10 * multiplier);

		const moveToX = (310 * multiplier);

		const moveToY = (180 * multiplier);


		for (var x = (offsetLeft + addOffsetLeft); x < (gridWidth - right); x += spriteSize) {
			lineContext.moveTo(x, moveToX);
			lineContext.lineTo(x, (height - bottom));
		}

		for (var y = (offsetTop + addOffsetTop) ; y < (height - bottom); y += spriteSize) {
			lineContext.moveTo(moveToY, y);
			lineContext.lineTo((gridWidth - right), y);
		}

		lineContext.strokeStyle="#EAEAE9";
		lineContext.stroke();
	}

	function handleBuy(event) {
		event.persist();

		const lines = lineRef.current;
		const lineContext = lines.getContext("2d");

		const fullCart = new Image();

		fullCart.src = sprites.square;

		const canvas = container.current.getBoundingClientRect();

		const addOffsetLeft = ((40 / 2) * multiplier);

		const coordinatesTop = ((20 / 2) * multiplier);

		const addOffsetTop = ((20 / 2) * multiplier); // (to position image not coordinates)

		let x = Math.ceil((((event.clientX - canvas.left) / (canvas.right - canvas.left) * canvas.width) - (offsetLeft + addOffsetLeft)) / spriteSize);

		let y = Math.ceil(((((event.clientY - canvas.top) / (canvas.bottom - canvas.top) * canvas.height) - offsetTop) - coordinatesTop) / spriteSize);

		let tree = getTypeForSale(x, y);

		if(tree) {
			fullCart.onload = function() {
				lineContext.drawImage(fullCart, ((tree.positionX * spriteSize) + offsetLeft), ((tree.positionY * spriteSize) + (offsetTop - addOffsetTop)), (20 * multiplier), (20 * multiplier));
			};

			if(tree) {
				onBuy(tree);
			}
		}
	}

	function getTypeForSale(x, y) {
		const permittedTime = 1000 * 60 * 30;

		const timeAgo = Date.now() - permittedTime;

		const longtime = Date.now() - (permittedTime * 1000);

		const double = (inCart.length ? inCart.filter(el => el.positionX === x && el.positionY === y) : false);

		if(double.length) {
			return;
		}

		return trees.find(item => item.positionX === x
			&& item.positionY === y
			&& item.type !== ""
			&& item.sold !== true
			&& ((item.reserved ? new Date(item.reserved) : longtime) < timeAgo)
			&& item.type !== "bigtree"
		);
	}

	function getTypeSold(x, y) {
		return trees.find(item => ((item.positionX === x
			&& item.positionY === y
			&& (item.sold === true || item.type === "bigtree")) || "")) ;
	}

	function getTypeReserved() {
		return trees.filter(item => ((item.reserved ? new Date(item.reserved) : longtime) > timeAgo) && !item.sold && item.reserved !== false);
	}

	function getTypeReservedSingle(x, y) {
		const reserved = getTypeReserved();

		return reserved.find(item => item.positionX === x && item.positionY === y);
	}

	if(isMobile) {
		return (
			<section className="global-canvas">
				<TransformWrapper
					wheenEnalbled={false}
					touchPadEnabled={false}
					zoomIn={{ disabled: true }}
					zoomOut={{ disabled: true }}
					doubleClick={{ disabled: false }}
					options={{
						minScale: 1,
						maxScale: 1,
						limitToBounds: false,
						centerContent: false,
					}}
					wheel={{
						disabled: true,
					}}
					defaultPositionY={(-566.6 * multiplier)}
					defaultPositionX={(233.3 * multiplier)}
				>
					<TransformComponent>
						<section>
							<div className="canvas" ref={container} >
								<canvas ref={messageRef}
									{...props}
									width={(1264 * multiplier)}
									height={(1380 * multiplier)}
									onMouseMove={hoverText}
									onClick={(event) => handleBuy(event)}
									className="messages"
								/>
								<canvas ref={forSaleRef} width={(1264 * multiplier)} height={(1380 * multiplier)} className="forsale"/>
								<canvas ref={lineRef} {...props} width={(1264 * multiplier)} height={(1380 * multiplier)}   className="upper"/>
								<canvas ref={canvasRef} {...props} width={(1264 * multiplier)} height={(1380 * multiplier)}  className="lower"/>
							</div>
						</section>
					</TransformComponent>
				</TransformWrapper>
			</section>
		);
	}

	if(isBrowser) {
		return (
			<section className="global-canvas">
				<TransformWrapper
					wheenEnalbled={false}
					touchPadEnabled={false}
					zoomIn={{ disabled: true }}
					zoomOut={{ disabled: true }}
					doubleClick={{ disabled: false }}
					options={{
						minScale: 1,
						maxScale: 1,
						limitToBounds: false,
						centerContent: false,
					}}
					wheel={{
						disabled: true,
					}}
					defaultPositionY={(-566.6 * multiplier)}
					defaultPositionX={(283.3 * multiplier)}
				>
					<TransformComponent>
						<section>
							<div className="canvas" ref={container} >
								<canvas ref={messageRef}
									{...props}
									width={(1264 * multiplier)}
									height={(1380 * multiplier)}
									onMouseMove={hoverText}
									onClick={(event) => setTimeout(() => handleBuy(event), 20)}
									className="messages"
								/>
								<canvas ref={forSaleRef} width={(1264 * multiplier)} height={(1380 * multiplier)} className="forsale"/>
								<canvas ref={lineRef} {...props} width={(1264 * multiplier)} height={(1380 * multiplier)}   className="upper"/>
								<canvas ref={canvasRef} {...props} width={(1264 * multiplier)} height={(1380 * multiplier)}  className="lower"/>
							</div>
						</section>
					</TransformComponent>
				</TransformWrapper>
			</section>
		);
	}
};

export default Canvas;
