import React, { useState } from "react";
import PropTypes from "prop-types";

import { keyEvents } from "../../../helpers";

export default function AIconButton({
	active,
	label,
	icon,
	className,
	open,
	onClick,
	...props
}) {
	const [isActive, setActiveState] = useState(active);

	const handleClick = (event) => {
		if (onClick && !props.disabled) {
			onClick(event);
		}

		setActiveState(!isActive);
	};
	const handleKeyUp = (event) => {
		if (keyEvents.enterOrSpacePressed(event) && onClick) {
			handleClick();
		}
	};

	const cName = `a-button ${className} ${isActive ? "active" : ""}`;

	return (
		<button
			{...props}
			className={cName}
			onClick={handleClick}
			aria-label={label || icon}
			aria-disabled={props.disabled}
			onKeyUp={handleKeyUp}
		>
			<div className={`container ${open}`}>
				<div className="bar1"></div>
				<div className="bar2"></div>
				<div className="bar3"></div>
			</div>
		</button>
	);
}

AIconButton.propTypes = {
	icon: PropTypes.string.isRequired,
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	iconclass: PropTypes.string,
	onClick: PropTypes.func,
};

AIconButton.defaultProps = {
	type: "button",
	icon: "",
	label: undefined,
	value: "",
	active: false,
	disabled: false,
	className: "",
	iconclass: "",
	onClick: undefined,
};
