import React from "react";

import { Image } from "../../atoms";
import { graphics } from "../../../assets/images";
import ReactMarkdown from "react-markdown";

export default function OOverOnsModal({
	className,
	Vrijwilligers,
	VrijwilligersImage,
	Lokaal,
	LokaalImage,
	Natuur,
	NatuurImage,
	Milieu,
	MilieuImage,
	Open,
	OpenImage,
}) {
	return (
		<section className={`o-section o-section-over-ons-modal ${className}`}>
			<Image.ABackground src={graphics.blog} className="o-section--home-blog-graphic"/>
			<div className="o-section--modal">
				{VrijwilligersImage?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${VrijwilligersImage?.formats?.medium.url}`} className="o-section--modal-img"/>}
				<div className="o-section--content-container">
					<p className="o-section--title"><span>/</span>VRIJWILLIGERS</p>
					<p className="subtext">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Vrijwilligers}
						/>
					</p>
				</div>
			</div>
			<div className="o-section--modal">
				{LokaalImage?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${LokaalImage?.formats?.medium.url}`} className="o-section--modal-img"/>}
				<div className="o-section--content-container">
					<p className="o-section--title"><span>/</span>LOKAAL</p>
					<p className="subtext">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Lokaal}
						/>
					</p>
				</div>
			</div>
			<div className="o-section--modal">
				{NatuurImage?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${NatuurImage?.formats?.medium.url}`} className="o-section--modal-img"/>}
				<div className="o-section--content-container">
					<p className="o-section--title"><span>/</span>NATUUR</p>
					<p className="subtext">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Natuur}
						/>
					</p>
				</div>
			</div>
			<div className="o-section--modal">
				{MilieuImage?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${MilieuImage?.formats?.medium.url}`} className="o-section--modal-img"/>}
				<div className="o-section--content-container">
					<p className="o-section--title"><span>/</span>MILIEU</p>
					<p className="subtext">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Milieu}
						/>
					</p>
				</div>
			</div>
			<div className="o-section--modal">
				{OpenImage?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${OpenImage?.formats?.medium.url}`} className="o-section--modal-img"/>}
				<div className="o-section--content-container">
					<p className="o-section--title"><span>/</span>OPEN</p>
					<p className="subtext">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Open}
						/>
					</p>
				</div>
			</div>
		</section>
	);
}
