import React from "react";

import { Article, List } from "../../molecules";

export default function OOverTeam({
	Teamlid,
}) {
	return (
		<section className="o-section--over-ons-team">
			<h2><span>/</span>Team</h2>
			<div className="team-container">
				<List.MDefault
					items={Teamlid}
					className="team-container"
				>
					<Article.MTeamMember/>
				</List.MDefault>
			</div>
		</section>
	);
}
