import axios from "axios";
import store from "../../store";

import actionTypes from "./cart.actionTypes";
import { apiConfig } from "../../../config";

export const isFetching = () => dispatch => {
	dispatch({
		type: actionTypes.CART_IS_FETCHING,
	});
};
export const fetchingFailed = () => dispatch => {
	dispatch({
		type: actionTypes.FETCH_CART_FAILED,
	});
};
export const fetchingSucceeded = () => dispatch => {
	dispatch({
		type: actionTypes.FETCH_CART_SUCCEEDED,
	});
};

export const fetchTrees = () => async dispatch => {
	dispatch(isFetching());

	const result = await axios.get(apiConfig.api.getAll);

	try {
		dispatch({
			type: actionTypes.FETCH_TREES,
			data: {
				trees: result.data,
			},
		});

		dispatch(fetchingSucceeded());

	} catch (error) {
		dispatch(fetchingFailed());

	}
};

export const updateCart = (item) => async dispatch => {
	try {
		const newItems = [...store.getState().content.cart.cart, item];

		const result = axios.post(apiConfig.api.availability, { data: newItems, reserved: true });

		dispatch({
			type: actionTypes.UPDATE_CART,
			data: {
				items: newItems,
				reserved: result,
			},
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const createOrder = ({ data }) => async dispatch => {

	 const cart = store.getState().content.cart.cart;
	 const total = store.getState().content.cart.total;

	const result = await axios.post(apiConfig.api.order, { data: data, cart: cart, total: total });

	try {
		dispatch({
			type: actionTypes.GET_PAYMENT_URL,
			data: result.data.paymentUrl,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};

export const validateOrder = (orderId) => async dispatch => {
	dispatch(isFetching());

	const result = await axios.get(`${apiConfig.api.orderSucces}?orderId=${orderId.succes}`);

	const validation = result.data.paid || false;

	try {
		dispatch({
			type: actionTypes.ORDER_VALIDATION,
			data: {
				valid: validation,
			},
		});

		dispatch(fetchingSucceeded());

	} catch (error) {
		dispatch(fetchingFailed());

	}
};

export const deleteOrder = () => async dispatch => {
	try {
		dispatch(isFetching());

		const items = store.getState().content.cart.cart;
		const x = await axios.post(apiConfig.api.availability, { data: items, reserved: false });

		dispatch({
			type: actionTypes.DELETE_ORDER,
			data: x,
		});

		dispatch(fetchingSucceeded());
	} catch (error) {
		dispatch(fetchingFailed());
	}
};
