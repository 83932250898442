import React from "react";
import { Route } from "react-router-dom";

import ScrollToTop from "./scroll-to-top.route";
import defaultRoutes from "./default.routes";

const Routing = () => (
	<ScrollToTop>
		<Route component={defaultRoutes} />
	</ScrollToTop>
);

export default Routing;
