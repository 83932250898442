import React from "react";

import { Article, List } from "../../molecules";

export default function OCheckoutAggregate({
	items,
	totaal,
}) {
	return (
		<section className="o-section o-section-checkout-aggregate">
			<p className="section-title">Overzicht</p>
			<List.MDefault
				items={items}
				className="o-section-checkout-aggregate-wrapper"
			>
				<Article.MCheckoutAggregateItem />
			</List.MDefault>
			<div className="line"></div>
			<p className="totaal">
				€{totaal}
			</p>
		</section>
	);
}
