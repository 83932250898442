import React, { useState } from "react";
import { Link } from "react-router-dom";

import { pageConfig, routesConfig } from "../../../config";
import { Button, Image, Link as ALink } from "../../atoms";

export default function MDefault({ className }) {
	const [showNav, toggleNav] = useState(false);
	const handleClick = () => {
		toggleNav(!showNav);
	};

	return (
		<nav className={`m-navigation ${showNav ? "active" : ""} ${className}`}>
			<div className={`navigation--links ${showNav ? "active" : ""}`}>
				<div className="navigation--links-pages">
					{
						pageConfig.navigation.left.map(nav => (
							<ALink.ADefault
								key={nav.label}
								to={nav.href}
								onClick={() => toggleNav(false)}
								className="a-link--navigation"
							>
								{nav.label}
							</ALink.ADefault>
						))
					}
					<Link
						to={routesConfig.index}
						className="a-link--logo"
						onClick={() => toggleNav(false)}
					>
						<Image.ALogoRelative />
					</Link>
					{
						pageConfig.navigation.right.map(nav => (
							<ALink.ADefault
								key={nav.label}
								to={nav.href}
								onClick={() => toggleNav(false)}
								className="a-link--navigation"
							>
								{nav.label}
							</ALink.ADefault>
						))
					}
				</div>
			</div>
			<Button.AIconButton
				className={"navigation--mobile-control"}
				open={showNav ? "active" : "closed"}
				onClick={handleClick}
			/>
		</nav>
	);
}
