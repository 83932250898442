import React from "react";

import { Image } from "../../atoms";
import { graphics } from "../../../assets/images";

export default function OBlogHero({
	className,
	Subtitel,
}) {
	return (
		<section className="o-section--blog-hero">
			<h1>Blog</h1>
			<p>{Subtitel}</p>
			<Image.ABackground src={graphics.blog_boom_detail_rechts} className="o-section--blog-hero-right"/>
		</section>
	);
}
