import { Image as Images } from "../../atoms";
import React from "react";

export default function MTeamMember({
	Image,
	Naam,
	Omschrijving,
}){
	return(
		<div className="o-section--team">
			{Image?.url && <Images.ADefault src={`https://cms.planteenbos.be${Image?.url}`} className="o-section--team-img"/>}
			<div className="o-section--team-name-container">
				<div className="name">
					<p className="o-section--team-name">{Naam}</p>
				</div>
				<p className="subtext">{Omschrijving}</p>
			</div>
		</div>
	);
}

