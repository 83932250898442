import React from "react";

import { Section } from "../components/organisms";
import { connect } from "react-redux";
import { actions as pagesActions } from "../store/content/pages";

const mapStateToProps = state => ({
	buy: state.content.pages.buy,
	isFetching: state.content.blog.isFetching,
	fetchingSucceeded: state.content.blog.fetchingSucceeded,
});

export class BuyPage extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.fetchBuyPage();
	}

	render() {
		return (
			<main className="t-layout t-layout--buy">
				<Section.OBuy {...this.props.buy}/>
			</main>
		);
	}
}

export default connect(mapStateToProps, {
	fetchBuyPage: pagesActions.fetchBuy,
})(BuyPage);
