import React from "react";

import { graphics } from "../../../assets/images";
import { Image } from "../../atoms";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import ReactMarkdown from "react-markdown";

export default function OOrderSucces({
	title,
	description,
	succes,
}) {
	return (
		<section className="o-section o-section--cta">
			<div className="o-section--cta-content">
				<h2 className="o-section--cta-content-title">{title}</h2>
				<p className="o-section--cta-content-description order-succes">
					<ReactMarkdown
						parserOptions={{ commonmark: true }}
						source={description}
					/>				</p>
				<div>
					{succes && 	<FacebookShareButton
						url="http://planteenbos.be"
						quote="Ik kocht een stukje bos bij planteenbos.be"
						className="share"
					>
						<p>Laat het weten aan je vrienden!</p>
						<FacebookIcon size={32} round={true} />
					</FacebookShareButton>}

				</div>

			</div>
			<Image.ABackground src={graphics.checkout_image} className="t-layout--buy-graphic"/>
		</section>
	);
}
