import React from "react";

import { Image } from "../../atoms";

export default function MCheckoutListItem({
	type,
	itemPrice,
	positionX,
	positionY,
	amount,
	src,
}) {

	function calculatePrice(amount, itemPrice) {
		return amount * itemPrice;
	}

	return (
		<div className="m-article--checkout-listitem">
			<Image.ADefault src={src} />
			<p>{type}</p>
			<p className="position">POSITIE: {positionX} {positionY}</p>
			<p>1</p>
			{/*<p className="amount">€ {calculatePrice(amount, itemPrice)}</p>*/}
			<p className="amount">€ {itemPrice}</p>
		</div>
	);
}
