import React from "react";

import { keyEvents } from "../../../helpers";
import ListWrapper from "./_atoms.dropdown.list_wrapper";

export default class AContainer extends React.Component {
	constructor(props) {
		super(props);

		this.arrayOfRefs = [];
		this.headerRef = undefined;

		this.state = {
			active: props.active,
			focussedOption: undefined,
			selectedId: props.value,
		};

		this.bindHandles();
	}

	bindHandles() {
		this.setRef = this.setRef.bind(this);
		this.clearRef = this.clearRef.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleWrapperRef = this.handleWrapperRef.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleSelectFirst = this.handleSelectFirst.bind(this);
		this.handleSelectOption = this.handleSelectOption.bind(this);
		this.handleOptionKeyUp = this.handleOptionKeyUp.bind(this);
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.selectedId !== props.selectedId && props.selectedId) {
			return { selectedId: props.selectedId };
		}

		return null;
	}

	setRef(element) {
		if (element) {
			this.arrayOfRefs.push(element);
		}
	}
	clearRef() {
		this.arrayOfRefs = [];
	}

	handleToggle() {
		if(this.props.disabled) {
			return;
		}

		this.setState(prev => ({ active: !prev.active }));
	}

	handleClickOutside(event) {
		if (this.state.active && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ active: false });
		}
	}

	handleWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleKeyUp(event) {
		event.preventDefault();
		event.stopPropagation();

		if(this.props.disabled) {
			return;
		}

		if (keyEvents.enterOrSpacePressed(event)) {
			this.handleToggle();
		}

		if (keyEvents.escapePressed(event)) {
			this.setState({ active: false });
		}

		if (keyEvents.upArrowPress(event) || keyEvents.downArrowPress(event)) {
			this.handleSelectFirst(event);
		}
	}

	handleSelectFirst(event) {
		event.preventDefault();
		event.stopPropagation();
		const focusIndex = keyEvents.upArrowPress(event) ? this.props.items.length -1 : 0;

		this.setState({ active: true }, () => this.arrayOfRefs[focusIndex].focus());
	}

	handleSelectOption(item) {
		if(this.props.disabled) {
			return;
		}

		this.setState({
			active: false,
			selectedId: item.id,
		});

		if (this.props.onChange) {
			this.props.onChange(item.id);
		}
	}

	handleOptionKeyUp({ event, index }) {
		event.preventDefault();
		event.stopPropagation();

		if(this.props.disabled) {
			return;
		}

		if (keyEvents.downArrowPress(event) || keyEvents.upArrowPress(event)) {
			const focusIndex = keyEvents.upArrowPress(event) ? index - 1 : index + 1;

			if (focusIndex < 0 || focusIndex > this.arrayOfRefs.length - 1) {
				this.headerRef.focus();
			} else {
				this.arrayOfRefs[focusIndex].focus();
			}
		}

		if (keyEvents.enterOrSpacePressed(event)) {
			this.handleSelectOption(this.props.items[index]);
		}

		if (keyEvents.escapePressed(event)) {
			this.handleToggle();
		}
	}

	render() {
		let list;

		if (this.state.active) {
			list = (<ListWrapper
				items={this.props.items}
				selected={this.state.selectedId}
				onClick={this.handleSelectOption}
				onKeyUp={this.handleOptionKeyUp}
				render={this.props.render}
				setRef={this.setRef}
				className={this.props.listClassName}
			/>);
		} else {
			this.clearRef();
		}

		const selectedValue = this.props.items.find(item => item && item.id === this.state.selectedId);

		return (
			<div
				ref={this.handleWrapperRef}
				className={`a-dropdown ${this.state.active ? "active" : ""}
				${this.props.disabled ? "disabled" : ""} ${this.props.className}`}
			>
				{this.props.header({
					id: `${this.props.id}-header`,
					value: selectedValue ? selectedValue.value : undefined,
					placeholder: this.props.placeholder,
					disabled: this.props.disabled,
					active: this.state.active,
					onClick: this.handleToggle,
					onKeyUp: this.handleKeyUp,
					setRef: ref => this.headerRef = ref,
				})}
				{list}
			</div>
		);
	}
}
