import React from "react";
import emailjs from "emailjs-com";

class MContact extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			email: "",
			message: "",
			sent: "send",
		};
	}

	handleInputChange(event) {
		event.preventDefault();
		const target = event.target;
		const name = target.name;
		const value = target.value;

		this.setState({ [name]: value });
	}

	sendMessage(event) {
		event.preventDefault();

		const templateParams = {
			from_name: this.state.name,
			reply_to: this.state.email,
			to_name: "planteenbos.be",
			message_html: this.state.message,
		};

		emailjs.send("service_k7b7esh", "template_np540rt", templateParams, "user_u36SfxOjZR1LXy3lrGTA0");

		this.setState({
			name: "",
			email: "",
			message: "",
			sent: "message sent",
		});
	}

	render() {
		return(
			<div className={`m-form--contact ${this.props.className}`} >
				<form
					id={this.props.id}
					name={this.props.name}
					method={this.props.method}
					action={this.props.action}
				>
					<label htmlFor="name">Name</label>
					<input
						type="text"
						id="name"
						name="name"
						required
						value={this.state.name}
						onChange={this.handleInputChange.bind(this)}
					/>
					<label htmlFor="email">Email</label>
					<input
						type="email"
						id="email"
						name="email"
						required
						value={this.state.email}
						onChange={this.handleInputChange.bind(this)}
					/>
					<label htmlFor="message">Message</label>
					<textarea
						id="message"
						name="message"
						required
						value={this.state.message}
						onChange={this.handleInputChange.bind(this)}
					/>
					<input className="m-form--contact-submit" type="submit" value={this.state.sent} onClick={this.sendMessage.bind(this)} />
				</form>
			</div>
		);
	}
}

export default MContact;
