import React from "react";
import { connect } from "react-redux";

import { Header } from "../components/organisms";
import { Link, Image } from "../components/atoms";
import { graphics } from "../assets/images";
import { routesConfig } from "../config";

const mapStateToProps = state => ({
	cart: state.content.cart.cart,
	total: state.content.cart.total,
});

export class TemplatePage extends React.Component {
	render() {
		return (
			<section className="wrapper">
				<div className="basket">
					<Link.ADefault to={(routesConfig.koop_checkout_list)}>
						<div className="cart-content">
							{ this.props.total !== 0 &&  <p className="total">€ {this.props.total}</p> }
							{ this.props.cart && <p className="amount">{this.props.cart.length}</p>}
							<Image.ADefault src={graphics.cart} />
						</div>
					</Link.ADefault>
				</div>
				<div className="t-page t-layout t-layout--template">
					<Header.ODefault/>
					{this.props.children}
				</div>
			</section>
		);
	}
}

export default connect(mapStateToProps)(TemplatePage);
