import React from "react";
import { Image, Dropdown } from "../../atoms";

class MCheckoutGegevens extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			anoniem: false,
			email: "",
			gemeente: "",
			message: this.props.items,
			globalMessage: "",
			newsletter: true,
			bb_name: "",
			bb_adress: "",
			bb_postalcode: "",
			bb_gemeente: "",
			bb_vat: "",
			dropdown: "per_gebied",
			bb: false,
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === "checkbox" ? target.checked : target.value;


		this.setState( { [name]: value });
	}

	handleMessageChange(event) {
		event.preventDefault();

		const target = event.target;
		const name = target.name;
		const value = target.value;
		const current = this.state.message;

		current[name] = { ...current[name], message: value };

		this.setState( { message: current });
	}

	sendMessage(event) {
		event.preventDefault();

		this.props.handleSubmit(this.state);
	}

	handleDropdown(id) {
		this.setState({ dropdown: id });
	}

	render() {
		return(
			<section className={`o-section--checkout ${this.props.className}`} >
				<form
					id={this.props.id}
					name={this.props.name}
					method={this.props.method}
					action={this.props.action}
				>
					<input
						type="text"
						id="name"
						name="name"
						placeholder="NAAM"
						value={this.state.name}
						onChange={this.handleInputChange.bind(this)}
					/>
					<div id="anoniem-container">
						<input
							type="checkbox"
							id="anoniem"
							name="anoniem"
							checked={this.state.anoniem}
							onChange={this.handleInputChange.bind(this)}
						/>
						<p className="check-label">IK WIL ANONIEM BLIJVEN</p>
					</div>
					<input
						type="email"
						id="email"
						name="email"
						required
						placeholder="E-MAIL*"
						value={this.state.email}
						onChange={this.handleInputChange.bind(this)}
					/>
					<input
						type="gemeente"
						id="gemeente"
						name="gemeente"
						placeholder="GEMEENTE*"
						required
						value={this.state.gemeente}
						onChange={this.handleInputChange.bind(this)}
					/>
					<p className="required">*VERPLICHTE VELDEN</p>
					<div className="dropdown">
						<label htmlFor="message">PERSOONLIJKE BOODSCHAP</label>
						<Dropdown.ADefault onChange={this.handleDropdown.bind(this)}/>
					</div>
					{this.state.dropdown === "per_gebied" &&
						this.props.items.map((el, index) => (
							<div className="o-section--checkout-li">
								<Image.ADefault src={el.src} />
								<input
									id={`message${index}`}
									name={index}
									required
									maxLength="120"
									value={this.state.message[index].message}
									onChange={this.handleMessageChange.bind(this)}
								/>
							</div>
						))
					}
					{this.state.dropdown !== "per_gebied" &&
						<div className="o-section--checkout-global">
							<textarea
								id="globalMessage"
								name="globalMessage"
								required
								maxLength="120"
								value={this.state.globalMessage}
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					}
					<p className="disclaimer">MAX 120 KARAKTERS</p>
					<div id="newsletter-container">
						<input
							type="checkbox"
							id="newsletter"
							name="newsletter"
							checked={this.state.newsletter}
							onChange={this.handleInputChange.bind(this)}
						/>
						<p className="check-label" >Aanmelden voor nieuwsbrief</p>
					</div>
					<div id="anoniem-container">
						<input
							type="checkbox"
							id="bb"
							name="bb"
							checked={this.state.bb}
							onChange={this.handleInputChange.bind(this)}
						/>
						<p className="check-label">Kruis dit vakje aan indien u een betalingsbewijs wilt</p>
					</div>
					{this.state.bb &&
						<div className="bb">
							<input
								type="text"
								id="bb_name"
								name="bb_name"
								placeholder="NAAM*"
								value={this.state.bb_name}
								onChange={this.handleInputChange.bind(this)}
							/>
							<input
								type="text"
								id="bb_adress"
								name="bb_adress"
								placeholder="Adres*"
								value={this.state.bb_adress}
								onChange={this.handleInputChange.bind(this)}
							/>
							<input
								type="text"
								id="bb_postalcode"
								name="bb_postalcode"
								placeholder="Postcode*"
								value={this.state.bb_postalcode}
								onChange={this.handleInputChange.bind(this)}
							/>
							<input
								type="text"
								id="bb_gemeente"
								name="bb_gemeente"
								placeholder="Gemeente*"
								value={this.state.bb_gemeente}
								onChange={this.handleInputChange.bind(this)}
							/>
							<input
								type="text"
								id="bb_vat"
								name="bb_vat"
								placeholder="BTW-nummer*"
								value={this.state.bb_vat}
								onChange={this.handleInputChange.bind(this)}
							/>
							<p className="required">*VERPLICHTE VELDEN</p>
						</div>
					}
					<input
						className="m-form--contact-submit"
						type="submit"
						value="VOLTOOI"
						onClick={this.sendMessage.bind(this)}
						disabled={!!(!this.state.name.length && !this.state.email.length)}
					/>
				</form>
			</section>
		);
	}
}

export default MCheckoutGegevens;
