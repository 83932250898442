import actionTypes from "./cart.actionTypes";

const initialState = {
	cart: [],
	total: 0,
	trees: [],
	paymentUrl: "",
	valid: "",
	reserved: false,

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

function calculateTotal(items) {
	let total = 0;

	items.forEach(function(value) {
		total += (value.itemPrice * 1);
	});

	return total;
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.CART_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_CART_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_CART_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};
		case actionTypes.FETCH_TREES:
			return {
				...state,
				trees: action.data.trees,
			};
		case actionTypes.UPDATE_CART:
			return {
				...state,
				cart: action.data.items,
				total: calculateTotal(action.data.items),
				reserved: action.data.reserved,
			};
		case actionTypes.GET_PAYMENT_URL:
			return {
				...state,
				paymentUrl: action.data,
			};
		case actionTypes.ORDER_VALIDATION:
			return {
				...state,
				valid: action.data.valid,
			};
		case actionTypes.DELETE_ORDER:
			return {
				...state,
				cart: [],
				total: 0,
			};
		default:
			return state;
	}
}
