const root = {
	api: "https://cms.planteenbos.be", //eslint-disable-line no-undef
	server: "https://planteenbos.be", //eslint-disable-line no-undef
};

export default {
	root,
	blogs: {
		all: `${root.api}/blogs`,
		count: `${root.api}/blogs/count`,
		detail: `${root.api}/blogs?Slug=:slug`,
		categories: `${root.api}/categories`,
	},
	pages: {
		home: `${root.api}/home`,
		over_ons: `${root.api}/over-ons`,
		cta: `${root.api}/cta`,
		thanks: `${root.api}/bedankt`,
		contact: `${root.api}/contact`,
		blog_page: `${root.api}/blog-page`,
		buy: `${root.api}/koop`,
		validate: `${root.api}/validatie`,
	},
	api: {
		getAll: `${root.server}/api/v1/card/all`,
		order: `${root.server}/api/v1/card/order`,
		availability: `${root.server}/api/v1/card/availability`,
		orderSucces: `${root.server}/api/v1/card/order/verify`,
	},
};
