import React from "react";
import { connect } from "react-redux";

import { actions as blogActions } from "../store/content/blog";
import { Loader } from "../components/atoms";
import { Section } from "../components/organisms";
import { Article, List } from "../components/molecules";
import { routesConfig } from "../config";

const mapStateToProps = state => ({
	blogs: state.content.blog.all,
	lastThree: state.content.blog.lastThree,
	blogDetail: state.content.blog.detail,
	isFetching: state.content.blog.isFetching,
});

class BlogDetail extends React.Component {
	constructor(props) {
		super(props);

		this.bindHandles();

		this.quotes = [];
	}

	componentDidMount() {
		this.props.fetchBlogsBySlug(
			this.props.match.params.slug,
		);
		this.props.fetchLastThree();
		this.props.fetchBlogs();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.slug !== this.props.match.params.slug) {
			this.props.fetchBlogsBySlug(
				this.props.match.params.slug,
			);
		}
	}

	bindHandles() {
		this.buildLastThree = this.buildLastThree.bind(this);
		this.handleCardClick = this.handleCardClick.bind(this);
	}

	handleCardClick(id) {
		const blog = this.props.blogs.find((blog) => blog.id === id);

		this.props.history.push(routesConfig.blog.detail
			.replace(":slug", blog.Slug));
	}

	buildLastThree() {
		return (<div className="o-section o-section-blog-modal">
			{!this.props.isFetching ?
				<List.MDefault
					items={this.props.lastThree.filter(el => el.id !== this.props.blogDetail.id)}
				>
					<Article.MBlogCard
						onClick={this.handleCardClick}
					/>
				</List.MDefault> : <Loader.ADefault />}
		</div>);
	}

	render() {
		if (this.props.isFetching) {
			return <Loader.ADefault />;
		}

		console.log(this.props.blogDetail);

		return (
			<main className="t-layout t-page--blog-detail" >
				<Section.OBlogDetailHero
					{...this.props.blogDetail}
				/>
				{this.buildLastThree()}
				<Section.OCta />
			</main>
		);
	}
}

export default connect(mapStateToProps, {
	fetchBlogs: blogActions.fetchBlogs,
	fetchLastThree: blogActions.fetchLastThree,
	fetchBlogsBySlug: blogActions.fetchBlogBySlug,
})(BlogDetail);
