import React from "react";

import { Article, List } from "../../molecules";
import { routesConfig } from "../../../config";
import { Button, Link } from "../../atoms";
import { NavLink } from "react-router-dom";

export default function OCheckoutList({
	items,
	totaal,
}) {
	return (
		<section className="o-section o-section-checkout-list">
			<List.MDefault
				items={items}
				className="o-section-checkout-list-wrapper"
			>
				<Article.MCheckoutListItem />
			</List.MDefault>
			<div className="line"></div>
			<p className="totaal">
				TOTAAL €{totaal}
			</p>
			<div className="checkout-buttons">
				<NavLink to={routesConfig.koop_kaart} className="o-section--checkout-back">
					<p>Terug naar de kaart</p>
				</NavLink>
				<Link.ARound to={routesConfig.koop_checkout} className="o-section--cta-button">
					<p>Verder</p>
				</Link.ARound>
			</div>
		</section>
	);
}
