import React from "react";
import PropTypes from "prop-types";

export default function AIcon({
	type,
	value,
	className,
	active,
}) {
	const cName = `icon-${type} ${className} ${active ? "active" : ""}`;

	return <div className={cName} value={value} />;
}

AIcon.propTypes = {
	type: PropTypes.string,
	value: PropTypes.string,
	className: PropTypes.string,
	active: PropTypes.bool,
};

AIcon.defaultProps = {
	type: "",
	value: "",
	className: "",
	active: false,
};
