// A
// B
import * as AButton from "./atoms.button";
// C
import * as AComponent from "./atoms.component";
// D
import * as ADropdown from "./atoms.dropdown";
// E
// F
// G
// H
// I
import * as AImage from "./atoms.image";
// J
// K
// L
import * as ALink from "./atoms.link";
import * as ALoader from "./atoms.loader";
// M
// N
// O
// P
// Q
import * as AQuote from "./atoms.quote";
// R
// S
// T
// U
// V
// W
// X
// Y
// Z

export const Component = AComponent;
export const Dropdown = ADropdown;
export const Button = AButton;
export const Image = AImage;
export const Link = ALink;
export const Loader = ALoader;
export const Pen = AQuote;
