import React from "react";
import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import "core-js/stable";

import store from "./store/store";
import App from "./App";

const root = document.querySelector("#root");

if (root.hasChildNodes() === true) {
	Loadable.preloadReady().then(() => ReactDOM.hydrate(<Provider store={store}><App /></Provider>, root));
} else {
	ReactDOM.render(<Provider store={store}><App /></Provider>, root);
}
