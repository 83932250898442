import React from "react";
import ReactMarkdown from "react-markdown";

import { Image } from "../../atoms";

export default function OHomeHero({
	Subtitel,
	Omschrijving,
	className,
	Picture,
}) {
	return (
		<section>
			<div className={`o-section o-section--over-ons-hero ${className}`}>
				<div className="o-section--over-ons-header">
					<h1 className="o-section--over-ons-title">OVER ONS</h1>
					<h2><span>/</span>Filosofie</h2>
					<p>{Subtitel}</p>
				</div>
				{Picture?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${Picture?.formats?.large.url}`} className="o-section--over-ons-image"/>}
			</div>
			<div className="subtext" id="over-ons-subtext">
				<p className="padding">
					<ReactMarkdown
						parserOptions={{ commonmark: true }}
						source={Omschrijving}
					/>
				</p>
			</div>
		</section>
	);
}
