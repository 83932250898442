import React from "react";
import { Image } from "./../index";


export default function ADefault() {
	return (
		<section className="a-loader-default">
			<Image.ALogoRelative />
			<div className="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</section>
	);
}
