import React from "react";

import { Section } from "../components/organisms";
import { Article } from "../components/molecules";
import { routesConfig } from "../config";
import { Link, Loader, Button } from "../components/atoms";
import { connect } from "react-redux";
import { actions as cartActions } from "../store/content/cart";
import { actions as pagesActions } from "../store/content/pages";

const mapStateToProps = state => ({
	buy: state.content.pages.buy,
	cart: state.content.cart.cart,
	trees: state.content.cart.trees,
	isFetching: state.content.cart.isFetching,
});

export class BuyCardPage extends React.Component {
	constructor(props) {
		super(props);

		this.bindHandles();
	}

	componentDidMount() {
		this.props.fetchTrees();
		this.props.fetchBuyPage();
	}

	bindHandles() {
		this.handleCardCheckout = this.handleCardCheckout.bind(this);
		this.handleBuy = this.handleBuy.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleCardCheckout() {
		this.props.history.push(routesConfig.koop_checkout_list);
	}

	handleBuy(item) {
		this.props.updateCart(item);
	}

	handleDelete() {
		this.props.deleteOrder();
	}

	render() {
		if (!this.props.trees || !this.props.buy || this.props.isFetching) {
			return <Loader.ADefault />;
		}

		return (
			<main className="t-layout t-layout--buy">
				<Article.MBuyNavigation {...this.props.buy} active1={true} />
				<div className="o-section--canvas">
					{this.props.trees &&
					<Section.Canvas onBuy={this.handleBuy} trees={this.props.trees} inCart={this.props.cart}/>
					}
				</div>
				<div className="o-section--buy-control">
					{ !!this.props.cart.length &&
						<Button.ADefault onClick={this.handleDelete} className="o-section--buy-clear">
							<p>Alles wissen</p>
						</Button.ADefault>
					}
					{ !!this.props.cart.length && <Link.ARound onClick={this.handleCardCheckout} className="o-section--cta-button">
						<p>Bestel</p>
					</Link.ARound> }
				</div>
				<Article.MBuyLegend />
			</main>
		);
	}
}

export default connect(mapStateToProps, {
	fetchBuyPage: pagesActions.fetchBuy,
	fetchTrees: cartActions.fetchTrees,
	updateCart: cartActions.updateCart,
	deleteOrder: cartActions.deleteOrder,
})(BuyCardPage);
