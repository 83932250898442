import React from "react";
import { NavLink } from "react-router-dom";

export default function ACta({ to, className, children, onClick }) {
	return (<NavLink
		to={to}
		className={`a-link a-link-cta ${className}`}
		onClick={onClick}
		activeClassName="active"
		exact
	>
		{children}
	</NavLink>);
}
