import React from "react";
import ReactMarkdown from "react-markdown";

import { Image, Pen } from "../../atoms";

export default function OBlogDetailHero({
	Titel,
	Auteur,
	Datum,
	Body,
	Quote,
	Tag,
	Picture,
}) {
	return (
		<section className="o-section--blog-detail">
			{Picture?.url && <Image.ADefault src={`https://cms.planteenbos.be${Picture?.url}`} className="o-section--blog-detail-main"/>}
			<div className="main">
				<div className="m-article--blog-card-content">
					<p className="tag">{Tag}</p>
					<h2>{Titel}</h2>
					<p className="about">door {Auteur} Lukas op {Datum}</p>
					<p className="body">
						<ReactMarkdown
							parserOptions={{ commonmark: true }}
							source={Body}
						/>
					</p>
					{Quote && <Pen.ADefault quote={Quote} className="o-section--blog-detail-main-quote"/> }
				</div>
			</div>
		</section>
	);
}
