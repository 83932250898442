export default {
	BLOG_IS_FETCHING: "BLOG_IS_FETCHING",
	FETCH_BLOG_FAILED: "FETCH_BLOG_FAILED",
	FETCH_BLOG_SUCCEEDED: "FETCH_BLOG_SUCCEEDED",

	FETCH_BLOGS: "FETCH_BLOGS",
	FETCH_LAST_THREE: "FETCH_LAST_THREE",
	FETCH_FEATURED_BLOGS: "FETCH_FEATURED_BLOGS",
	FETCH_BLOG_BY_SLUG: "FETCH_BLOG_BY_SLUG",
};
