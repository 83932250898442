import React from "react";
import PropTypes from "prop-types";

export default function ADefault({ src, srcSet, alt, className }) {
	return <img src={src} srcSet={srcSet} alt={alt} className={`a-image a-image--default ${className}`} />;
}

ADefault.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
	alt: PropTypes.string,
	className: PropTypes.string,
};

ADefault.defaultProps = {
	alt: "",
	className: "",
};
