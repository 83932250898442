import React from "react";

import Mailchimp from "react-mailchimp-form";

export default function MNewsletter({

}) {
	return (
		<Mailchimp
			action='https://planteenbos.us12.list-manage.com/subscribe/post?u=5084d2b83d19d195258f23f77&amp;id=298e673563'
			fields={[
				{
					name: "FNAME",
					placeholder: "VOORNAAM",
					type: "text",
					required: true,
				},
				{
					name: "LNAME",
					placeholder: "NAAM",
					type: "text",
					required: true,
				},
				{
					name: "EMAIL",
					placeholder: "EMAIL",
					type: "email",
					required: true,
				},
			]}
			messages={
				{
					sending: "Sending...",
					success: "Thank you for subscribing!",
					error: "An unexpected internal error has occurred.",
					empty: "You must write an e-mail.",
					duplicate: "Too many subscribe attempts for this email address",
					button: "SEND",
				}
			}
			className="m-form--mailchimp"
		/>
	);
}
