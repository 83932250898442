import React from "react";
import { connect } from "react-redux";

import { Loader } from "../components/atoms";
import { Article, List } from "../components/molecules";
import { Section } from "../components/organisms";
import { routesConfig } from "../config";
import { actions as blogActions } from "../store/content/blog";
import { actions as pagesActions } from "../store/content/pages";

const mapStateToProps = state => ({
	blogs: state.content.blog.all,
	cta: state.content.pages.cta,
	blogPage: state.content.pages.blogpage,
	isFetching: state.content.blog.isFetching,
	fetchingSucceeded: state.content.blog.fetchingSucceeded,
});

export class BlogPage extends React.Component {
	constructor(props) {
		super(props);

		this.bindHandles();
	}

	componentDidMount() {
		this.props.fetchBlogs();
		this.props.fetchCta();
		this.props.fetchBlogPage();
	}

	bindHandles() {
		this.handleCardClick = this.handleCardClick.bind(this);
	}

	handleCardClick(id) {
		const blog = this.props.blogs.find((blog) => blog.id === id);

		this.props.history.push(routesConfig.blog.detail
			.replace(":slug", blog.Slug));
	}

	buildBody() {
		if (this.props.isFetching) {
			return <Loader.ADefault />;
		}

		return (<div className="blog-cards">
			{!this.props.isFetching ?
				<List.MDefault
					items={this.props.blogs}
					className="t-layout--blog-feed-list"
				>
					<Article.MBlogCard
						onClick={this.handleCardClick}
					/>
				</List.MDefault> : <Loader.ADefault />}
		</div>);
	}

	render() {
		console.log(this.props.blogPage);

		return (
			<main className="t-layout t-layout--blog">
				<Section.OBlogHero {...this.props.blogPage}/>
				{this.buildBody()}
				<Section.OCta {...this.props.cta}/>
			</main>
		);
	}
}

export default connect(mapStateToProps, {
	fetchBlogs: blogActions.fetchBlogs,
	fetchBlogPage: pagesActions.fetchBlogPage,
	fetchCta: pagesActions.fetchCta,
})(BlogPage);
