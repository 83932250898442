import React from "react";
import ReactMarkdown from "react-markdown";

export default function OOverHoe({
	HoeWerktHet,
	MijnStukje,
}) {
	return (
		<section className="o-section--over-ons-hoe">
			<h2 className="m-article--section-title">HOE WERKT HET?</h2>

			{/*<p className="subtitle">32m2 jong bos</p>*/}
			<p><ReactMarkdown
				parserOptions={{ commonmark: true }}
				source={HoeWerktHet}
			/></p>
			<h3>Wordt het echt mijn stukje bos?</h3>
			<p><ReactMarkdown
				parserOptions={{ commonmark: true }}
				source={MijnStukje}
			/></p>
		</section>
	);
}
