import { combineReducers } from "redux";

import {
	actions as blogActions,
	reducers as blogReducer,
} from "./blog";

import {
	actions as pagesActions,
	reducers as pagesReducer,
} from "./pages";

import {
	actions as cartActions,
	reducers as cartReducer,
} from "./cart";


export const actions = {
	blog: blogActions,
	pages: pagesActions,
	cart: cartActions,
};

export const reducers = combineReducers({
	blog: blogReducer,
	pages: pagesReducer,
	cart: cartReducer,
});

