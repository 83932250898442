import actionTypes from "./blog.actionTypes";

const initialState = {
	all: [],
	lastThree: [],
	detail: [],
	featured: [],

	isFetching: false,
	fetchingFailed: false,
	fetchingSucceeded: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.BLOG_IS_FETCHING:
			return {
				...state,
				isFetching: true,
				fetchingFailed: false,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_BLOG_FAILED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: true,
				fetchingSucceeded: false,
			};
		case actionTypes.FETCH_BLOG_SUCCEEDED:
			return {
				...state,
				isFetching: false,
				fetchingFailed: false,
				fetchingSucceeded: true,
			};
		case actionTypes.FETCH_FEATURED_BLOGS:
			return {
				...state,
				featured: action.data,
			};
		case actionTypes.FETCH_BLOGS:
			return {
				...state,
				all: action.data?.all,
			};
		case actionTypes.FETCH_LAST_THREE:
			return {
				...state,
				lastThree: action.data?.lastThree,
			};
		case actionTypes.FETCH_BLOG_BY_SLUG:
			return {
				...state,
				detail: action.data,
			};
		default:
			return state;
	}
}
