import React from "react";

import { Link } from "../../atoms";

export default function MHero({
	title,
	buttonLabel,
	to,
	className,
}) {
	return (
		<article className={`m-article--centered m-article--hero ${className}`}>
			<h2>{title}</h2>
		</article>
	);
}
