import routesConfig from "./routes.config";

export default {
	navigation: {
		fixedHeight: 150,
		left: [
			{
				label: "OVER ONS",
				href: routesConfig.over_ons,
			},
			{
				label: "KOOP",
				href: routesConfig.koop_kaart,
			},
		],
		right: [
			{
				label: "BLOG",
				href: routesConfig.blog.default,
			},
			{
				label: "CONTACT",
				href: routesConfig.contact,
			},
		],
	},
};
