// A
// B
// C
// D
// E
// F
// G
// H
import * as OHeader from "./organisms.header";
// I
// J
// K
// L
// M
// N
// O
// P
// Q
// R
// S
import * as OSection from "./organisms.section";
// T
// U
// V
// W
// X
// Y
// Z

export const Header = OHeader;
export const Section = OSection;
