export default {
	PAGES_IS_FETCHING: "PAGES_IS_FETCHING",
	FETCH_PAGES_FAILED: "FETCH_PAGES_FAILED",
	FETCH_PAGES_SUCCEEDED: "FETCH_PAGES_SUCCEEDED",

	FETCH_HOME: "FETCH_HOME",
	FETCH_OVER_ONS: "FETCH_OVER_ONS",
	FETCH_CTA: "FETCH_CTA",
	FETCH_THANKS: "FETCH_THANKS",
	FETCH_CONTACT: "FETCH_CONTACT",
	FETCH_BLOGPAGE: "FETCH_BLOGPAGE",
	FETCH_BUY: "FETCH_BUY",
	FETCH_VALIDATION: "FETCH_VALIDATION",
};
