export default {
	CART_IS_FETCHING: "CART_IS_FETCHING",
	FETCH_CART_FAILED: "FETCH_CART_FAILED",
	FETCH_CART_SUCCEEDED: "FETCH_CART_SUCCEEDED",

	FETCH_TREES: "FETCH_TREES",
	GET_PAYMENT_URL: "GET_PAYMENT_URL",

	UPDATE_CART: "UPDATE_CART",
	ORDER_VALIDATION: "ORDER_VALIDATION",
	DELETE_ORDER : "DELETE_ORDER",
};
