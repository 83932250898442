import React from "react";

import { graphics } from "../../../assets/images";
import { Image, Link } from "../../atoms";
import { routesConfig } from "../../../config";
import ReactMarkdown from "react-markdown";

export default function OCCta({
	Titel,
	Subtitel,
}) {
	return (
		<section className="o-section o-section--cta">
			<div className="o-section--cta-content">
				<h2 className="o-section--cta-content-title">{Titel}</h2>
				<p className="o-section--cta-content-description">
					<ReactMarkdown
						parserOptions={{ commonmark: true }}
						source={Subtitel}
					/>
				</p>
				<Link.ARound to={routesConfig.koop_kaart} className="o-section--cta-button">
					<p>Bekijk Kaart</p>
				</Link.ARound>
			</div>
			<Image.ABackground src={graphics.page_1_onderkant} className="o-section--cta-graphic"/>
		</section>
	);
}
