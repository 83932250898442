import React from "react";

import { Image, Link, Pen } from "../../atoms";
import { graphics } from "../../../assets/images";

export default function OHomeBlog({
	id,
	Titel,
	Body,
	Quote,
	onClick,
	className,
	Picture,
}) {
	return (
		<section className={`o-section o-section-home-blog ${className}`}>
			<Image.ABackground src={graphics.blog} className="o-section--home-blog-graphic"/>
			<div className="o-section--home-blog-content">
				{Picture?.formats?.medium.url && <Image.ADefault src={`https://cms.planteenbos.be${Picture?.formats?.medium.url}`} className="o-section--home-blog-content-image"/>}
				<div className="o-section--home-blog-content-inside">
					<h3 className="o-section--home-blog-content-title">{Titel}</h3>
					<p className="body">{Body}</p>
					{Quote && <Pen.ADefault quote={Quote} className="o-section--blog-detail-main-quote"/> }
					<Link.ACta onClick={() => onClick(id)}>
						<p>Lees meer op de blog</p>
					</Link.ACta>
				</div>
			</div>
			<Image.ABackground src={graphics.blogLetter} className="o-section--home-blog-graphic-letter"/>
		</section>
	);
}
